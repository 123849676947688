const initialState = {
    menuVisibility: false,
    surveyVisibility: false,
    fontSize: "100%",
    scrollPosition: { x: 0, y: 0 }
}

const app = ( state = initialState, action ) => {
    switch (action.type) {
        case 'TOGGLE_MENU_VISIBILITY':
            return { ...state, ...{ menuVisibility: action.visible } }

        case 'TOGGLE_SURVEY_VISIBILITY':
            return { ...state, ...{ surveyVisibility: !state.surveyVisibility } }

        case 'SET_SCROLL_POSITION':
            return { ...state, ...{ scrollPosition: action.position } }            
        default:
            return state
    }
}

export default app