import React, { useLayoutEffect } from 'react'
import { Route } from 'react-router-dom'
import axios from 'axios'
import Navbar from './Navbar'
import User from './user'
import CashFlow from './cashflow'

import './content.scss'

const Content = (props) => {
    useLayoutEffect( () => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + props.user.token
    }, [] )

    return (
        <div className="wrapper">
            <Navbar {...props} />
            <div className="container-fluid">
                <div className="content">
                    <Route exact path="/" render={ () => (
                        <>
                            <h3>Welcome {props.user.name},</h3>
                            <p className="h5 mt-4">Use the navigation menu above to access admin area of your website.</p>
                        </>
                    ) } />
                    <Route path="/users" render={ props => <User {...props} /> } />
                    <Route path="/cashflow" render={ props => <CashFlow {...props} /> } />
                </div>
            </div>
        </div>
    )
}

export default Content
