import { combineReducers } from 'redux'
import authentication from './authentication'
import UI from './UI'
import { users } from './users'
import { alert } from './alerts'

export default combineReducers({
	auth: authentication,
	UI,
	users,
	alert
})
