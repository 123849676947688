import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'
import Content from './Content'
import Login from './user/Login'
import { userActions, alertActions } from '../actions'
import { userService } from '../services/users'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope, faKey, faTimes, faBars, faCircle, faSearch, faUserAlt, faCaretLeft, faCaretRight, faChevronUp, faChevronDown, faChevronRight, faChevronLeft, faFileAlt, faFileExport, faSyncAlt, faMapMarkerAlt, faTools, faAddressCard, faStoreAlt, faTimesCircle, faPoll, faUser, faLock, faPlus, faFilter, faReplyAll, faPrint, faTrash, faReply, faLink, faArrowDown, faArrowUp, faSave } from '@fortawesome/free-solid-svg-icons'
import '../sass/app.scss'

library.add( faEnvelope, faKey, faTimes, faTimesCircle, faBars, faCircle, faSearch, faUserAlt, faCaretLeft, faCaretRight, faChevronUp, faChevronDown, faChevronRight, faChevronLeft, faFileAlt, faFileExport, faSyncAlt, faMapMarkerAlt, faTools, faAddressCard, faStoreAlt, faPoll, faUser, faLock, faPlus, faFilter, faReply, faReplyAll, faPrint, faTrash, faLink, faArrowUp, faArrowDown, faSave )

const mapState = state => ({
    auth: state.auth,
    alert: state.alert
})

const actions = dispatch => ({
	signIn: (email, password, e) => {
        dispatch(userActions.loginRequest(email))
        dispatch(alertActions.info(`Authenticating ${String.fromCharCode(8594)} ${email}`))
        
        userService
            .login({ email, password })
                .then(
                    response => { 
                        dispatch(userActions.loginSuccess(response.data))
                    },
                    error => {
                        let msg
                        if ( error.response ) {
                            msg = error.response.status !== 500 ? error.response.data : 'Oops, something went wrong'
                        } else {
                            msg = error.toString()
                        }                        
                        
                        dispatch(userActions.loginFailure())
                        dispatch(alertActions.error(msg))
                    }
                )

		e.preventDefault()
    },
    
    signOut: () => {
        userService.logout()
        dispatch(userActions.logout())
    },

    clearAlert: () => dispatch(alertActions.clear())
})

const App = ({ auth, signIn, alert, clearAlert, ...props }) => {
    const myAlert = useAlert()
    
    useEffect(() => {
        if ( 'message' in alert ) {
            myAlert.show( alert.message, {
                type: alert.typeAlias,
                onOpen: () => clearAlert()
            } )
        }
    }, [ alert, myAlert, clearAlert ])

    return (
        <BrowserRouter>
            { auth.loggedIn ? <Content user={auth.user} {...props} /> : <Login signIn={signIn} /> }
        </BrowserRouter>
    )
}

App.propTypes = {
    auth: PropTypes.object.isRequired,
    signIn: PropTypes.func.isRequired,
    alert: PropTypes.object.isRequired,
    clearAlert: PropTypes.func.isRequired    
}

export default connect( mapState, actions )(App)
