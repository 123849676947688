import React from 'react'
import { NavLink } from 'react-router-dom'
import { Navbar, Nav, Dropdown, Button, ButtonGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import app from '../config/app'

const AdminNavbar = ({ user, signOut }) => {
    
    return (
        <Navbar expand="md" variant="dark" bg="dark" fixed="top">
            <NavLink to="/" className="navbar-brand">
                <span className="font-weight-bold" style={{ fontVariant: 'unicase' }}>{app.name}</span>
            </NavLink>
            
            <Navbar.Toggle aria-controls="admin-navbar" />

            <Navbar.Collapse id="admin-navbar">
                <Nav className="mr-auto">
                    <NavLink to="/users" className="nav-link">Users</NavLink>
                    <NavLink to="/cashflow" className="nav-link">Cash Flows</NavLink>
                </Nav>

                <div className="my-1">
                    <Dropdown as={ButtonGroup}>
                        <Button variant="secondary"><i><FontAwesomeIcon icon="user" /></i> {user.email}</Button>

                        <Dropdown.Toggle split variant="secondary" id="admin-dropdown-split" />

                        <Dropdown.Menu>
                            <Dropdown.Item as="button" onClick={signOut}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Navbar.Collapse>
        </Navbar> 
    )

}

export default AdminNavbar
